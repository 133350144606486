
import { Component, Vue } from "vue-property-decorator";
import {
    _videoResources,
    sourceChannelText,
    SourcetypeText,
} from "@/utils/pageConfig/videovips/videoResources";
import { Foundation } from "@/utils/Foundation";
import { _address } from "@/utils/pageConfig/_address";
import VideoApi from "@/utils/API/videovip/videoApi";

@Component({
    name: "addMerchant",
})
export default class extends Vue {
    title: string = "新增";
    pageConfig: any = _videoResources;
    sourceChannelText: any = sourceChannelText;
    SourcetypeText: any = SourcetypeText;
    formData: any = {
        sourceName: "",
        sourceFrom: "",
        sendType: "",
        thirdSourceNo: "",
        sourceImg: "",
        sourceRemark: "",
        salePrice: 0,
        purchasePrice: "",
        sourceUnit: "",
    };
    form: any = {};
    thirdParty: any = []; //第三方资源数组
    addrOptions: any = _address;
    address: Array<string> = ["", "", ""];
    defaultOption: any = {
        value: "label",
        label: "label",
        children: "children",
    };
    isLoading: boolean = false;

    async show(data: any = {}) {
        (this.$refs.addvideo as any).show();
        await this.handleThird();
        this.$nextTick(() => {
            this.title = data.id ? "编辑" : "新增";
            this.form = data.id
                ? Foundation.deepClone(data)
                : Foundation.deepClone(this.formData);
            if (data.id) {
                console.log(this.form.thirdSourceNo, this.thirdParty);
                let moneyobj: any =
                    this.thirdParty.find(
                        (v: any) => v["resourceNo"] == this.form.thirdSourceNo
                    ) || {};
                console.log(moneyobj,"选中的资源---");
                this.form.itemFace = moneyobj.itemVal;
            }
            this.form.salePrice = Foundation.toYUAN(data.salePrice);
            this.form.purchasePrices = Foundation.toYUAN(data.purchasePrice);
            this.form.sendType = data.id ? data.sendTypeEm : null;
            this.form.sourceFrom = data.id ? data.sourceFromEm : null;
            (this.$refs.addForm as any).clearValidate();
        });
    }

    hide() {
        (this.$refs.addForm as any).resetFields();
        (this.$refs.addvideo as any).hide();
    }

    /* 获取第三方所有资源 */
    async handleThird() {
        // 第一版只有直充方式
        let res: any = await VideoApi.getAllThirdResource({
            sourceFrom: "GZZX",
            sendType: "DIRECT_CHARGE",
        });
        this.thirdParty = res;
    }

    /* 回显第三方资源id */
    handleThirdId(e: any) {
        console.log(e);
        this.form.thirdSourceNo = e;
        this.form.thirdSourceName = Foundation.findInArray(
            this.thirdParty,
            "resourceNo",
            e
        ).resourceName;
        let moneyobj: any =
            this.thirdParty.find((v: any) => v["resourceNo"] == e) || {};
        console.log(moneyobj);
        this.form.purchasePrice = moneyobj.salePrice;
        this.form.purchasePrices = Foundation.toYUAN(moneyobj.salePrice);
        this.form.itemFace = moneyobj.itemVal;
    }

    /*表单提交*/
    async submitForm() {
        let form = Foundation.resetParams(this.form);

        (this.$refs.addForm as any).validate(async (valid: any) => {
            if (valid) {
                if (this.isLoading) return;
                this.isLoading = true;
                try {
                    form.purchasePrice =
                        form.purchasePrice == 0 ? 100000 : form.purchasePrice;
                    form.salePrice = Foundation.toMoney(form.salePrice);
                    await VideoApi.addvideo(form);
                    this.isLoading = false;
                    this.$message.success("操作成功");
                    this.hide();
                    this.$emit("submitForm", form);
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                return false;
            }
        });
    }

    /*图片上传*/
    uploadImg() {
        (this.$refs.imgChoice as any).show();
    }

    /*选择图片*/
    choiceImg(img: any) {
        this.$set(this.form, "sourceImg", img[0].ossUrl);
    }
}
